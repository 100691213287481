import submitContact from "../../../api/mutations/submitContact";
import { resetFormValidation } from "../../../utils/form";
import AsyncStatus from "../../../utils/async";
import NotificationsMixin from "../../ui/NotificationsMixin";
import mixins from "vue-typed-mixins";
import { rethrowPossibleApolloErrorAsUserMessage } from "../../../api/apolloErrorToUserMessage";
function createEmptyFormValues() {
    return {
        name: "",
        emailAddress: "",
        phoneNumber: "",
        weChatId: "",
        message: "",
    };
}
export default mixins(NotificationsMixin).extend({
    data: function () {
        return {
            honeyPotValue: "",
            form: createEmptyFormValues(),
            status: new AsyncStatus(),
        };
    },
    methods: {
        onSubmit: function () {
            var _this = this;
            this.$validator.validate().then(function (valid) {
                if (valid) {
                    _this.status
                        .run(_this.$apollo
                        .mutate(submitContact(_this.form))
                        .catch(rethrowPossibleApolloErrorAsUserMessage))
                        .then(function () {
                        _this.form = createEmptyFormValues();
                        resetFormValidation(_this);
                        _this.displaySuccessMessage(_this.$t("Your contact has been sent"));
                    })
                        .catch(_this.displayErrorMessage);
                }
            });
        },
    },
});
